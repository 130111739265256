<template>
  <div>
  <h1 style="display:flex;justify-content:center;">{{module.fileName?module.fileName:'Single module'}}</h1>
  <div style="display: flex; flex-wrap:wrap;margin-top: 30px;  ">
      <div style="width: 50%" class="c-img">
        <img style="width: 70%;" v-if="module.jpegIsFail" :src="module.dataUri"/>
        <svg v-if="!module.jpegIsFail"
          id="theCanvas"
          ref="svgCanvas"
          xmlns="http://www.w3.org/2000/svg"
          :width="canvasScaleWidth"
          :height="canvasScaleHeight"
          :viewBox="`0 0 ${canvasWidth} ${canvasHeight}`"
          :key="canvasWasRecreated"
        >
          <rect
            :width="canvasWidth"
            :height="canvasHeight"
            :key="canvasWasRecreated"
          />

          <g
            pointer-events="all"
            style="pointer-events: bounding-box;"
            v-html="module.svgString"
          />
        </svg>
    </div>
      <div  style="width: 50%" class="description">
        <div >
          <div>
            <label
              class="aggregated_field_add_button"
              :class="module.tags.length?'bg_5':''"
              style="font-size: 14px; color:black"
              @click="module.tags=[]"
            >Clear</label><label
            class="aggregated_field_add_button"
            :class="module.tags.length < tagsLibrary.length?'bg_2':''"
            style="font-size: 14px; color:black"
            @click="module.tags=tagsLibrary"
          >Show all</label>
          </div>
          <span
            v-for="(tg,index) in tagsLibrary"
            :key="`tag${index}`"

          >

           <label
             class="aggregated_field_add_button"
             :class="module.tags.includes(tg)?'bg_1':''"
             style="font-size: 14px; color:black"
           >{{tg}}<input @click="tagWasEdited=true" type="checkbox" :value="tg" v-model="module.tags"></label>
      </span>
        </div>
        <div class="c-section-add" style="margin-top: 30px;">

          <input
            id="module_upload"
            ref="fileInput"
            class="c-section-add__input"
            type="file"
            accept=".svg"
            @change="replaceModule"

          >
          <label style="width: 130px;"
            for="module_upload" class="c-section-add__link">
            <span class="c-section-add__link-inner">
                          Replace
                        </span>
          </label>
        </div>

      </div>
    <div style="width: 100%;margin-top: 30px; flex-wrap: wrap; display:flex;justify-content:center;">
      <button @click="saveChanges" class="aggregated_field_add_button bg_2" style=" font-size: 20px">Save & exit</button>
      <button @click="refresh" class="aggregated_field_add_button bg_3" style=" font-size: 20px">Discard changes</button>
      <button v-if="!shure"  @click="shure=true" class="aggregated_field_add_button bg_5" style=" font-size: 20px">Delete from database</button>
      <div v-if="shure" style="display:flex; justify-content:center; font-size: 20px; margin-top: 20px;margin-bottom: 20px; width: 100%;">The module data will be deleted from the database. This action may affect public collections and other users of the system. </div>
      <button @click="deleteTheModules" v-if="shure" class="bg_1 aggregated_field_add_button bg_5" style=" font-size: 20px">I know what I'm doing, delete </button>
      <button @click="shure=false" v-if="shure" class="bg_1 aggregated_field_add_button bg_2" style=" font-size: 20px">No, better leave it as it is </button>

    </div>
  </div>
  </div>

</template>
<script>
// import Modules from '../components/sections/Modules.vue'

import { parse } from 'postsvg'
import render from 'posthtml-render'
import { getAverageColor } from 'fast-average-color-node'

export default {
  components: {
    // Modules
  },
  data () {
    return {
      module: { svgString: '', tags: [], dataUri: '', fileName: '' },
      key: this.$route.query.mid,
      tagsLibrary: [],
      dbCollections: [],
      canvasScaleWidth: 96,
      canvasScaleHeight: 96,
      canvasHeight: 96,
      canvasWidth: 96,
      canvasWasRecreated: false,
      shure: false,
      tagWasEdited: false,
      svgWasEdited: false
    }
  },
  async mounted () {
    window.addEventListener('keydown', this.keyListener, false)
    window.addEventListener('keyup', e => {
      this.currentKey = null
      if (['Shift', 'Control', 'Alt', 'Meta'].includes(e.key)) {
        if (!this.secondKeyModifier) {
          this.keyModifier = null
        }
        if (this.keyModifier !== this.secondKeyModifier) {
          this.secondKeyModifier = null
        } else { this.keyModifier = null }
      }
    })
    this.getDirectDbData()
  },
  watch: {
  },
  methods: {
    async getDirectDbData () {
      await this.$store.dispatch('getSingleModulesDataFromTable', this.key)
      this.module = this.$store.getters.data
      const name = JSON.parse(localStorage.modulesStorage).filter(t => t.indexer === this.key)[0].fileName
      this.module.fileName = name
      this.module.jpegIsFail = Boolean(this.module.dataUri.indexOf('ata:image/svg+xml') + 1)
      const atags = await this.db_a('tags')
      this.tagsLibrary = []
      atags.forEach(tg => {
        this.tagsLibrary.push(tg.data)
      })
      await this.$store.dispatch('getAllCollectionData')
      this.dbCollections = this.$store.getters.data
    },
    async db_a (table) {
      await this.$store.dispatch('getDataFromTable', table)
      return this.$store.getters.data
    },
    async db_q (table, data, user) {
      await this.$store.dispatch('saveDataToTable', { table: table, data: data, user: user })
    },
    async db_p (path, array, user) {
      await this.$store.dispatch('putArrayToTable', { path: path, array: array, user: user })
    },
    async db_d (table, id) {
      await this.$store.dispatch('deleteDataFromTable', { table: table, id: id })
    },
    async db_v (table, id) {
      await this.$store.dispatch('getValueFromTable', { table: table, id: id })
      return JSON.parse(this.$store.getters.data)
    },
    cl (e) {
      console.log(e)
    },
    tagEdit (tg) {
      if (this.module.tags.includes(tg)) {
        this.module.tags.splice(this.module.tags.indexOf(tg), 1)
      } else {
        this.module.tags.push(tg)
      }
      this.tagWasEdited = true
    },
    replaceModule (e) {
      this.uploadingFiles = Array.from(e.target.files)
      this.uploadingFiles.forEach(file => {
        this.uploadFile(file)
      })
    },
    uploadFile (file) {
      const reader = new FileReader()
      const tags = (file.name.replace(/\d/g, '').split('.')[0].split('-'))
      reader.readAsText(file)
      reader.onerror = () => {
        this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1)
      }
      reader.onload = () => {
        Promise.resolve(parse(reader.result))
          .then(svgTree => {
            delete svgTree.root.attrs['data-name']
            delete svgTree.root.attrs.id
            delete svgTree.root.attrs['xmlns:xlink']
            delete svgTree.root.attrs.x
            delete svgTree.root.attrs.y
            delete svgTree.root.attrs.style
            delete svgTree.root.attrs['xml:space']
            delete svgTree.root.attrs.version

            let width = 96
            let height = 96

            if (svgTree.root.attrs.viewBox) {
              width = svgTree.root.attrs.viewBox.split(' ')[2]
              height = svgTree.root.attrs.viewBox.split(' ')[3]
            } else if (svgTree.root.attrs.width) {
              width = svgTree.root.attrs.width
              height = svgTree.root.attrs.height
            }

            svgTree.root.attrs.width = 96
            svgTree.root.attrs.height = 96

            svgTree.root.content.unshift({ tag: 'rect', attrs: { width: width, height: height, fill: 'none' } })

            const defs = svgTree.select('defs')[0]
            if (defs) {
              svgTree.root.content.splice(svgTree.root.content.indexOf(defs), 1)
            }
            const title = svgTree.select('title')[0]
            if (title) {
              svgTree.root.content.splice(svgTree.root.content.indexOf(title), 1)
            }
            const style = svgTree.select('style')[0]
            if (style) {
              svgTree.root.content.splice(svgTree.root.content.indexOf(style), 1)
            }

            return svgTree
          })
          .then(svgTree => render(svgTree.root))
          .then(svgHtml => {
            this.$emit('addIndexer')
            const width = 96
            const height = 96
            var canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height
            var ctx = canvas.getContext('2d')
            var img = document.createElement('img')
            img.setAttribute('src', 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgHtml))))
            img.onload = () => {
              ctx.clearRect(0, 0, width, height)
              ctx.fillStyle = 'white'
              ctx.fillRect(0, 0, width, height)
              ctx.drawImage(img, 0, 0, width, height)
              const jpegString = canvas.toDataURL('image/jpeg')
              getAverageColor(canvas.toDataURL('image/png')).then(color => {
                this.module = {
                  selected: false,
                  svgString: svgHtml,
                  tags: tags.filter(tag => tag !== ''),
                  dataUri: 'data:image/svg+xml;base64,' + window.btoa(reader.result),
                  jpegString: color.hex === '#000000' ? 'data:image/svg+xml;base64,' + window.btoa(reader.result) : jpegString,
                  jpegIsFail: color.hex === '#000000'
                }
              })
              this.canvasWasRecreated = !this.canvasWasRecreated
            }
            this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1)
            this.$emit('input', this.modules)
          })
      }
      this.svgWasEdited = true
    },
    refresh () {
      this.$router.go()
    },
    async deleteTheModules () {
      await this.$store.dispatch('deleteListElementFromDatabase', { type: 'fullmodules', id: this.key })
      await this.$store.dispatch('deleteListElementFromDatabase', { type: 'mod_keys', id: this.key })
      await this.$store.dispatch('trigger', 1)
      this.$router.push('/modules')
    },
    async saveChanges () {
      if (this.tagWasEdited) {
        await this.$store.dispatch('setNewDataToTable', { type: `mod_keys/${this.key}`, data: { tags: JSON.stringify(this.module.tags) } })
        await this.$store.dispatch('trigger', 1)
      }
      if (this.svgWasEdited) {
        const d = new Date()
        const now = d.getTime()
        await this.$store.dispatch('setNewDataToTable', {
          type: `fullmodules/${this.key}`,
          data: {
            svgString: this.module.svgString,
            dataUri: this.module.jpegString,
            upd: now
          }
        })
        await this.$store.dispatch('trigger', 2)
      }

      this.$router.push('/modules')
    }
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyListener, false)
  }
}
</script>
